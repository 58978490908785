import {Col, Layout, Row} from 'antd'
import React from 'react'

class PageLoading extends React.Component {

  render() {
    const {minHeight = '250px'} = this.props
    return (
      <Layout style={{minHeight}}>
        <Row type='flex' justify='center' align='middle' style={{minHeight}}>
          <Col align='center'>
            <Puff size={80} stroke='white'/>
          </Col>
        </Row>
      </Layout>
    )
  }
}

export default PageLoading

export const Puff = ({size, strokeWidth = 1, stroke = 'rgba(65,13,244,1)'}) => <svg width={size} height={size}
                                                                                    stroke={stroke} viewBox="0 0 45 45">
  <g fill="none" fillRule="evenodd" strokeWidth={strokeWidth}>
    <circle cx="22" cy="22" r="18.9682">
      <animate attributeName="r" begin="0s" dur="2.8s" values="1; 20" calcMode="spline" keyTimes="0; 1"
               keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"/>
      <animate attributeName="stroke-opacity" begin="0s" dur="2.8s" values="1; 0" calcMode="spline"
               keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"/>
    </circle>
    <circle cx="22" cy="22" r="19.9618">
      <animate attributeName="r" begin="-0.9s" dur="2.8s" values="1; 20" calcMode="spline" keyTimes="0; 1"
               keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite"/>
      <animate attributeName="stroke-opacity" begin="-0.9s" dur="2.8s" values="1; 0" calcMode="spline"
               keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite"/>
    </circle>
  </g>
</svg>
