import {Alert, Layout} from 'antd'
import React, {Component} from 'react'
import TopMenu from '../components/TopMenu'
import styles from '../less/BaseLayout.module.less'
import logo from '../resources/img/hero-logo-dark.svg'
import {firestoreConnect} from 'react-redux-firebase'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {BreakpointsProvider} from 'react-with-breakpoints'
import _ from 'lodash'
import PageLoading from './PageLoading'
import FreshChat from 'react-freshchat'
import {subscriptionLevels} from './Admin/UserManagement'
import {freshChatToken} from '../config/freshChat'

const {Header, Content} = Layout

const breakpoints = {
  small: 576,
  medium: 768,
  large: 1200,
  xlarge: Infinity
}

class BaseLayout extends Component {

  render() {
    if (this.props.auth && this.props.auth.isEmpty) {
      // Check that users is logged in and not empty. Else redirect to login
      return window.location.href = '/'
    }

    const {configs, subscriptionLevel, auth, profile, firestore} = this.props

    const fullyLoaded = _.get(configs, 'tutorials') && _.get(configs, 'brokerProviders')
      && _.get(this.props.profile, 'mainCurrency') // createUser has been completed

    const announcement = _.get(configs, 'announcement', {})

    return (
      <BreakpointsProvider breakpoints={breakpoints}>
        {(announcement.show && announcement.alwaysShow) &&
        <Alert
          className='announcementBanner'
          type='warning'
          message={announcement.title}
          description={<div dangerouslySetInnerHTML={{__html: announcement.message}}/>}
        />
        }
        <Layout className={styles.baseLayout}>
          <Header className={styles.header}>
            <div className={styles.topMenuWrapper}>
              <div className={styles.logoWrapper}>
                <img src={logo} alt="Oddshero" className={styles.logo}/>
                <div className={styles.beta}>beta</div>
              </div>
              <TopMenu announcement={announcement} admin={this.props.admin}
                       subscriptionLevel={this.props.subscriptionLevel}
                       auth={this.props.auth}/>
            </div>
          </Header>
          <Content className={styles.content}>
            {fullyLoaded
              ? this.props.children
              : <PageLoading/>
            }
          </Content>
          {subscriptionLevel > 0 &&
          <FreshChat
            token={freshChatToken}
            config={{headerProperty: {direction: 'ltr' }}}
            externalId={auth.email}
            restoreId={profile.freshChatRestoreId}
            siteId="ODDSHERO"
            onInit={widget => {
              widget.user.setProperties({
                email: auth.email,
                uid: auth.uid,
                displayName: auth.displayName,
                subscriptionLevel: subscriptionLevels[subscriptionLevel]
              })

              widget.on('user:created', function (resp) {
                const status = resp && resp.status, data = resp && resp.data
                if (status === 200) {
                  if (data.restoreId) {
                    firestore.collection('userprofiles').doc(auth.uid)
                      .update({freshChatRestoreId: data.restoreId})
                  }
                }
              })
            }}
          />
          }
        </Layout>
      </BreakpointsProvider>
    )
  }
}

export default compose(
  connect((state, props) => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    configs: state.firestore.data.configs,
    admin: state.claims.admin,
    subscriptionLevel: state.claims.subscriptionLevel
  })),
  firestoreConnect(props => [
    {collection: 'configs', doc: 'tutorials'},
    {collection: 'configs', doc: 'brokerProviders'},
    {collection: 'configs', doc: 'announcement'},
    {collection: 'xrates'}
  ])
)(BaseLayout)
