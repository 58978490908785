import {Avatar, Button, Card, Descriptions, Input, List, message, Modal, Tag} from 'antd'
import React, {Component} from 'react'
import connect from 'react-redux/es/connect/connect'
import {compose} from 'redux'
import {getUser, getUsers, manageUser, reTriggerSetup} from '../../config/firebaseConfig'
import * as Sentry from '@sentry/browser'
import moment from 'moment'

export const subscriptionLevels = {
  1: 'Basic',
  2: 'Premium'
}

class UserManagement extends Component {
  state = {
    users: [],
    nextPageToken: null,
    selectedUser: null,
    openUserModal: false
  }

  setAdmin = async (userId, admin) => {
    this.setState({setAdminLoading: true})
    try {
      await manageUser({
        userId,
        authUpdates: {admin}
      })
      await this.searchUser(userId)
    } catch (e) {
      message.error('Something went wrong!')
      Sentry.captureException(e)
    }
    this.setState({setAdminLoading: false})
  }

  setSubscriptionLevel = async (userId, subscriptionLevel) => {
    this.setState({setSubscriptionLevelLoading: true})
    try {
      await manageUser({
        userId,
        authUpdates: {subscriptionLevel}
      })
      await this.searchUser(userId)
    } catch (e) {
      message.error('Something went wrong!')
      Sentry.captureException(e)
    }
    this.setState({setSubscriptionLevelLoading: false})
  }

  triggerSetup = async (userId) => {
    this.setState({triggerSetupLoading: true})
    try {
      await reTriggerSetup(userId)
      await this.searchUser(userId)
    } catch (e) {
      message.error('Something went wrong!')
      Sentry.captureException(e)
    }
    this.setState({triggerSetupLoading: false})
  }

  componentDidMount() {
    this.loadMore()
  }

  loadMore = () => {
    this.setState({usersLoading: true})
    getUsers({pageToken: this.state.nextPageToken}).then(response => {
      this.setState({usersLoading: false})
      this.setState(
        {
          users: [...this.state.users, ...response.users],
          nextPageToken: response.nextPageToken
        }
      )
    }).catch(e => {
      this.setState({usersLoading: false})
      message.error('Something went wrong!')
      Sentry.captureException(e)
    })
  }

  searchUser = async (searchValue) => {
    const user = await getUser({searchValue})
    let {users} = this.state
    const index = users.findIndex(usersUser => usersUser.uid === user.uid)

    if (index !== -1) {
      users = [...users]
      users[index] = user
      this.setState({users: users})
    }

    this.setState({selectedUser: user})
  }

  quickOpenUser = (searchValue) => {
    this.setState({selectedUser: null, searchLoading: true})
    this.searchUser(searchValue)
      .then(
        this.setState({searchLoading: false, openUserModal: true})
      )
      .catch(e => {
        this.setState({searchLoading: false})
        message.error(e.message)
      })
  }

  openUserModal = (user) => () => {
    this.setState({selectedUser: user, openUserModal: true})
  }

  closeUserModal = () => {
    this.setState({openUserModal: false})
  }


  render() {
    const {users, usersLoading, nextPageToken, searchLoading, selectedUser, openUserModal, setAdminLoading, setSubscriptionLevelLoading, triggerSetupLoading} = this.state

    return (
      <Card title="User Management" bordered={false} className='layout-top-margin'>
        <div style={{marginBottom: '2em'}}>
          <Input.Search
            placeholder="Quick open User by Email / UID"
            enterButton="Open"
            loading={searchLoading}
            onSearch={value => this.quickOpenUser(value)}
          />
        </div>
        {selectedUser &&
        <Modal
          title={<>
            {selectedUser.disabled ? <Tag>DISABLED</Tag> : null}
            {selectedUser.admin ? <Tag color='red'>ADMIN</Tag> : null}
            {selectedUser.email}
          </>}
          maskClosable
          visible={openUserModal}
          onCancel={this.closeUserModal}
          footer={[
            !selectedUser.subscriptionLevel &&
            <Button loading={triggerSetupLoading}
                    onClick={() => this.triggerSetup(selectedUser.uid)}>Retrigger Setup</Button>,
            selectedUser.admin
              ? <Button loading={setAdminLoading} type='danger'
                        onClick={() => this.setAdmin(selectedUser.uid, false)}>Remove Admin</Button>
              : <Button loading={setAdminLoading} type='danger'
                        onClick={() => this.setAdmin(selectedUser.uid, true)}>Make Admin</Button>,
            selectedUser.subscriptionLevel === 2
              ? <Button loading={setSubscriptionLevelLoading}
                        onClick={() => this.setSubscriptionLevel(selectedUser.uid, 1)}>Remove Premium</Button>
              : <Button loading={setSubscriptionLevelLoading}
                        onClick={() => this.setSubscriptionLevel(selectedUser.uid, 2)}>Make Premium</Button>
          ]}
        >
          <Descriptions bordered size='small' colon={false} column={1}>
            <Descriptions.Item label="UID"><code>{selectedUser.uid}</code></Descriptions.Item>
            <Descriptions.Item label="Name">{selectedUser.displayName || <>&ndash;</>}</Descriptions.Item>
            <Descriptions.Item label="Subscription Level">
              {subscriptionLevels[selectedUser.subscriptionLevel] || <>&ndash;</>} ({selectedUser.subscriptionLevel || 'missing'})
            </Descriptions.Item>
            <Descriptions.Item label="Created">
              {moment(selectedUser.creationTime).calendar()}
            </Descriptions.Item>
            <Descriptions.Item label="Last Sign In">
              {moment(selectedUser.lastSignInTime).fromNow()}
            </Descriptions.Item>
          </Descriptions>
        </Modal>
        }
        <List
          loadMore={nextPageToken ? <div style={{marginTop: '1em', textAlign: 'center'}}>
            <Button loading={usersLoading} onClick={this.loadMore}>Load More</Button>
          </div> : null}
          loading={usersLoading}
          dataSource={users}
          rowKey={user => user.email}
          renderItem={user => <>
            <List.Item actions={[<Button onClick={this.openUserModal(user)}>Open</Button>]}>
              <List.Item.Meta
                avatar={
                  <Avatar src={user.avatar}/>
                }
                title={<>
                  {user.disabled ? <Tag>DISABLED</Tag> : null}
                  {user.admin ? <Tag color='red'>ADMIN</Tag> : null}
                  {user.email}
                </>}
                description={
                  <Descriptions size='small' colon={false} column={{xs: 1, sm: 1, md: 2, xl: 3}}>
                    <Descriptions.Item label="UID"><code>{user.uid}</code></Descriptions.Item>
                    <Descriptions.Item label="Name">{user.displayName || <>&ndash;</>}</Descriptions.Item>
                    <Descriptions.Item label="Subscription Level">
                      {subscriptionLevels[user.subscriptionLevel] || <>&ndash;</>} ({user.subscriptionLevel || 'missing'})
                    </Descriptions.Item>
                    <Descriptions.Item label="Created">
                      {moment(user.creationTime).calendar()}
                    </Descriptions.Item>
                    <Descriptions.Item label="Last Sign In">
                      {moment(user.lastSignInTime).fromNow()}
                    </Descriptions.Item>
                  </Descriptions>
                }
              />
            </List.Item>
          </>
          }
        />
      </Card>
    )
  }
}

export default compose(
  connect((state, props) => ({
    auth: state.firebase.auth
  }))
)(UserManagement)










