import React, {Component} from 'react'
import {Button, Card, Col, Divider, Form, Icon, Input, message, Row, Typography} from 'antd'
import ForgotPasswordModal from './ForgotPasswordModal'
import {Link} from 'react-router-dom'

const {Text} = Typography

class NormalLoginForm extends Component {

  state = {
    emailLoading: false,
    googleLoading: false,
    facebookLoading: false
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({emailLoading: true})
        this.props.emailLogin(values.email, values.password)
          .catch(e => {
            this.setState({emailLoading: false})
            message.error(e.message)
          })
      }
    })
  }

  handleGoogle = async () => {
    this.setState({googleLoading: true})
    try {
      await this.props.googleSignup()
    } catch (e) {
      this.setState({googleLoading: false})
      message.error(e.message)
    }
  }

  handleFacebook = async () => {
    this.setState({facebookLoading: true})
    try {
      await this.props.facebookSignup()
    } catch (e) {
      this.setState({facebookLoading: false})
      message.error(e.message)
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form
    return (
      <>
        <Card bordered={false} bodyStyle={{paddingBottom: '32px', paddingTop: '32px'}}>
          <Row>
            <Col>
              <Button loading={this.state.googleLoading}
                      icon="google" type="danger" block onClick={this.handleGoogle}>Log in with Google</Button>
            </Col>
          </Row>
          <Row style={{marginTop: '15px'}}>
            <Col>
              <Button loading={this.state.facebookLoading} type="primary" block onClick={this.handleFacebook}><Icon
                type="facebook" theme="filled"/>Log in with Facebook</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Divider style={{color: '#8598ad', fontWeight: '500', fontSize: '14px', margin: '20px 0'}}>or</Divider>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item style={{marginBottom: 0}}>
                  {getFieldDecorator('email', {
                    rules: [{required: true, message: 'Please input your email!'}]
                  })(
                    <Input
                      prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}}/>}
                      placeholder="Email"
                      type="email"
                    />
                  )}
                </Form.Item>
                <Form.Item style={{marginBottom: '10px'}}>
                  {getFieldDecorator('password', {
                    rules: [{required: true, message: 'Please input your Password!'}]
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                      type="password"
                      placeholder="Password"
                    />
                  )}
                </Form.Item>
                <Form.Item style={{marginBottom: '10px'}}>
                  <Button loading={this.state.emailLoading} block type="primary" htmlType="submit"
                          className="login-form-button">
                    Log in
                  </Button>
                </Form.Item>
                <ForgotPasswordModal sendResetPassword={this.props.sendResetPassword}/>
              </Form>
            </Col>
          </Row>
        </Card>
        <Text type='secondary' style={{display: 'block', marginTop: '15px'}}>
          Don't have an account? <Link to='/signup'>Sign up</Link>
        </Text>
      </>
    )
  }
}

const LoginForm = Form.create({name: 'normal_login'})(NormalLoginForm)

export default LoginForm
