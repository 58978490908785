const defaultClaims = {
  subscriptionLevel: 0,
  admin: false,
  lastUpdate: 0
}

export function claimsReducer(state = defaultClaims, action) {
  switch (action.type) {
    case 'ID_TOKEN_CLAIMS':
      return {
        subscriptionLevel: action.claims.subscriptionLevel,
        admin: action.claims.admin,
        lastUpdate: action.lastUpdate
      }
    default:
      return state
  }
}