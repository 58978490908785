import React, {Component} from 'react'
import {Layout, message} from 'antd'
import {IntlProvider} from 'react-intl'
import Login from '../components/Authentication/Login'
import PageLoading from '../components/PageLoading'
import {Redirect, Route, Switch} from 'react-router-dom'

import * as firebase from 'firebase/app'
import 'firebase/auth'
import VerifyEmail from '../components/Authentication/VerifyEmail'
import styles from '../less/BaseLayout.module.less'
import {connect} from 'react-redux'
import Action from './action'
import {firebaseConnect} from 'react-redux-firebase'
import {compose} from 'redux'


class Authentication extends Component {
  googleSignup = () => {
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider()
    return firebase.auth().signInWithPopup(googleAuthProvider)
  }

  facebookSignup = () => {
    const facebookAuthProvider = new firebase.auth.FacebookAuthProvider()
    return firebase.auth().signInWithPopup(facebookAuthProvider)
  }

  emailSignup = (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password)
  }

  emailLogin = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password)
  }

  sendResetPassword = (email) => {
    return firebase.auth().sendPasswordResetEmail(email)
  }

  componentDidMount() {
    if (window.location.hash === '#emailVerified') {
      message.success('Email verified')
      window.location.hash = ''
    }
  }


  render() {
    const {auth, profile} = this.props

    if (!auth.isLoaded) {
      return (
        <Layout className={styles.baseLayout}>
          <PageLoading/>
        </Layout>
      )
    }

    return (
      <Switch>
        <Route path='/action' render={routeProps => <Action {...routeProps}/>}/>
        <Route path={['/login', '/signup']}>
          {!auth.isEmpty
            ? <Redirect to='/dashboard'/>
            : <Login
              googleSignup={this.googleSignup}
              facebookSignup={this.facebookSignup}
              emailLogin={this.emailLogin}
              sendResetPassword={this.sendResetPassword}
              emailSignup={this.emailSignup}
            />
          }
        </Route>
        <Route>
          {auth.isEmpty
            ? <Redirect to='/login'/>
            : !auth.emailVerified
              ? <VerifyEmail auth={auth} profile={profile}/>
              : <IntlProvider locale='en'>
                {this.props.children}
              </IntlProvider>
          }
        </Route>
      </Switch>
    )
  }
}

export default compose(
  connect(state => ({
    auth: state.firebase.auth,
    profile: state.firebase.profile
  })),
  firebaseConnect()
)(Authentication)






