import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import Authentication from './pages/authentication'
import {StripeProvider} from 'react-stripe-elements'
import './less/App.less'
import sagas from './store/sagas'
import 'moment/locale/en-gb'
import {createFirestoreInstance} from 'redux-firestore'
import {ReactReduxFirebaseProvider} from 'react-redux-firebase'
import {applyMiddleware, compose, createStore} from 'redux'
import rootReducer from './store/reducers'
import createSagaMiddleware from 'redux-saga'
import firebase from './config/firebaseConfig'
import moment from 'moment'
import * as Sentry from '@sentry/browser'

// Prod
const stripePublicKey = 'pk_live_RDKNupHRlRGocHR1K5rJUVet00S004yZoi'
// Test
// const stripePublicKey = 'pk_test_UQ6YByQF95Yq0rxBebpodxkN00Stncbd11'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({dsn: 'https://d361b062f6894b90b3c911adcabbb72f@sentry.io/1798502'})
} else {
  console.log('Sentry disabled because env is ' + process.env.NODE_ENV)
}

moment.locale('en-gb')

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(sagaMiddleware)
  )
)

sagaMiddleware.run(sagas)


// react-redux-firebase config
const rrfConfig = {
  userProfile: 'userprofiles',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}


// firebase.auth().applyActionCode()

// registerServiceWorker()

const render = Component => {
  return ReactDOM.render(
    <Provider store={store}>
      <ReactReduxFirebaseProvider
        firebase={firebase}
        config={rrfConfig}
        dispatch={store.dispatch}
        createFirestoreInstance={createFirestoreInstance}
      >
        <BrowserRouter>
          <Authentication>
            <StripeProvider apiKey={stripePublicKey}>
              <Component/>
            </StripeProvider>
          </Authentication>
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('root')
  )
}

render(App)

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    render(NextApp)
  })
}
