import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import firebase, {createUser, verifiedUser} from '../config/firebaseConfig'

export async function sendVerificationEmail(currentUser) {
  await firebase.firestore().collection('userprofiles')
    .doc(currentUser.uid)
    .update({emailVerificationSent: true})
  await currentUser.sendEmailVerification()
}

function* onSetProfile() {
  yield takeLatest('@@reactReduxFirebase/SET_PROFILE', function* ({profile}) {
    
    if (!profile || !profile.mainCurrency) {
      yield call(createUser)
    }

    if (!profile) return

    const currentUser = firebase.auth().currentUser
    if (!currentUser.emailVerified) {
      if (!profile.emailVerificationSent) {
        yield call(sendVerificationEmail, currentUser)
      }
    }

    if (!profile.verifiedSetupDone && currentUser.emailVerified) {
      // noinspection JSIgnoredPromiseFromCall
      verifiedUser() // No await, can be done in background
    }

    const lastUpdate = yield select(state => state.claims.lastUpdate)
    const refresh = lastUpdate < profile.lastTokenUpdate

    if (refresh) {
      const result = yield currentUser.getIdTokenResult(true)
      yield put({type: 'ID_TOKEN_CLAIMS', claims: result.claims, lastUpdate: profile.lastTokenUpdate})
    }
  })
}

function* sagas() {
  return yield all([
    onSetProfile()
  ])
}

export default sagas