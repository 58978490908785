import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/auth'

export const config = {
  apiKey: 'AIzaSyCSOUUhxMX5G9TMgIZrBWsnuN-D-kzJOIE',
  authDomain: 'app.oddshero.io',
  databaseURL: 'https://inv-matched-betting.firebaseio.com',
  projectId: 'inv-matched-betting',
  storageBucket: 'gs://inv-matched-betting.appspot.com/',
  messagingSenderId: '508470396010',
  appId: '1:508470396010:web:fec129ef40f3624b'
}

const firebaseApp = firebase.initializeApp(config)
firebase.firestore()
firebase.storage()

const functions = firebaseApp.functions('europe-west1')
// const functions = firebase.functions()
// firebase.functions().useFunctionsEmulator('http://localhost:5001')

export const getIdToken = () => {
  return firebase.auth().currentUser.getIdToken()
}

export const createUser = async () => {
  const response = await functions.httpsCallable('createUser')()
  return response.data
}

export const newStripeSessionId = async () => {
  const response = await functions.httpsCallable('newStripeSession')()
  return response.data
}

export const updateSubscription = async (data) => {
  const response = await functions.httpsCallable('updateSubscription')(data)
  return response.data
}

export const manageUser = async (data) => {
  const response = await functions.httpsCallable('manageUser')(data)
  return response.data
}

export const getUsers = async (data) => {
  const response = await functions.httpsCallable('getUsers')(data)
  return response.data
}

export const getUser = async (data) => {
  const response = await functions.httpsCallable('getUser')(data)
  return response.data
}

export const reTriggerSetup = async (userId) => {
  const response = await functions.httpsCallable('reTriggerSetup')({userId})
  return response.data
}

export const tradeUpdated = async (tradeId) => {
  const response = await functions.httpsCallable('tradeUpdated')({tradeId})
  return response.data
}

export const bonusUpdated = async (bonusId) => {
  const response = await functions.httpsCallable('bonusUpdated')({bonusId})
  return response.data
}

export const verifiedUser = async () => {
  const response = await functions.httpsCallable('verifiedUser')()
  return response.data
}

export default firebase
