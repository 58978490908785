import 'antd/dist/antd.css'
import './resources/font/hk-grotesk/style.css'
import React, {Component, lazy, Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {hot} from 'react-hot-loader/root'
import BaseLayout from './components/BaseLayout'
import PageLoading from './components/PageLoading'
import {unregister} from './lib/registerServiceWorker'
import ErrorBoundary from './ErrorBoundary'

const Dashboard = lazy(() => import('./pages/dashboard'))
const Tradefeed = lazy(() => import('./pages/tradefeed'))
const Settings = lazy(() => import('./pages/settings'))
const MyProfile = lazy(() => import('./pages/myProfile'))
const MyTrades = lazy(() => import('./pages/myTrades'))
const HowTo = lazy(() => import('./pages/howto'))
const Admin = lazy(() => import('./pages/admin'))

unregister()


class App extends Component {
  render() {
    return (
      <BaseLayout>
        <ErrorBoundary>
          <Suspense fallback={<PageLoading/>}>
            <Switch>
              <Redirect exact from='/' to='/dashboard'/>
              <Route path='/dashboard' render={routeProps => <Dashboard {...routeProps}/>}/>
              <Route path='/tradefeed' exact render={routeProps => <Tradefeed {...routeProps}/>}/>
              <Route path='/tradefeed/:bonusId/:slug' render={routeProps => <Tradefeed {...routeProps}/>}/>
              <Route path='/settings' render={routeProps => <Settings {...routeProps}/>}/>
              <Route path='/my-profile' render={routeProps => <MyProfile {...routeProps}/>}/>
              <Route path='/my-bets' exact render={routeProps => <MyTrades {...routeProps}/>}/>
              <Route path='/my-bets/:bonusId/:slug' render={routeProps => <MyTrades {...routeProps}/>}/>
              <Route path='/how-it-works' render={routeProps => <HowTo {...routeProps}/>}/>
              <Route path='/admin/:page' render={routeProps => <Admin {...routeProps}/>}/>
              <Route path='/admin/:page/:sportId' render={routeProps => <Admin {...routeProps}/>}/>
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </BaseLayout>
    )
  }
}

export default hot(App)






