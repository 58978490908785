import React from 'react'
import styles from '../less/BaseLayout.module.less'
import {Button, Card, Col, Form, Icon, Input, Layout, message, Row, Typography} from 'antd'
import qs from 'qs'
import {gutter} from '../lib/layout'
import logo from '../resources/img/hero-logo.svg'
import {Link, withRouter} from 'react-router-dom'
import {Puff} from '../components/PageLoading'
import * as Sentry from '@sentry/browser'
import {firebaseConnect} from 'react-redux-firebase'

const {Title, Paragraph, Text} = Typography

class Action extends React.Component {

  state = {
    loading: true,
    query: {}
  }

  constructor(props) {
    super(props)
    const search = this.props.location.search
    if (search) {
      this.state.query = qs.parse(search.substr(1))
    }
  }

  async componentDidMount() {
    const {firebase} = this.props
    try {
      const {oobCode} = this.state.query
      if (!oobCode) {
        throw new Error('\'The OOB Code is missing.')
      }
      switch (this.state.query.mode) {
        case 'verifyEmail':
          await firebase.auth().applyActionCode(oobCode)
          window.location.replace('/#emailVerified')
          break
        case 'resetPassword':
          await firebase.auth().verifyPasswordResetCode(oobCode)
          this.setState({loading: false})
          break
        default:
          throw new Error(`Unknown action (${this.state.query.mode}).`)
      }
    } catch (e) {
      this.setState({loading: false, error: e.message})
      Sentry.captureException(e)
    }
  }

  savePasswordReset = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          this.setState({passwordResetLoading: true})
          await this.props.firebase.auth().confirmPasswordReset(this.state.query.oobCode, values.password)
          this.props.history.replace('/')
          message.success('Password reset successful')
        } catch (e) {
          this.setState({passwordResetLoading: false})
          message.error(e.message)
        }
      }
    })
  }

  render() {
    const {query} = this.state
    const {getFieldDecorator} = this.props.form

    let component
    if (!this.state.error) {
      switch (query.mode) {
        case 'verifyEmail':
          component = <>
            <Title level={3}>Verifying your Email.</Title>
          </>
          break
        case 'resetPassword':
          component = <>
            <Title level={3}>Reset password.</Title>
            {!this.state.loading &&
            <>
              <Paragraph>Set your new password below.</Paragraph>
              <Form onSubmit={this.savePasswordReset}>
                <Form.Item style={{marginBottom: '10px'}}>
                  {getFieldDecorator('password', {
                    rules: [{required: true, message: 'Please input your new Password!'}]
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                      type="password"
                      placeholder="New Password"
                    />
                  )}
                </Form.Item>
                <Button style={{marginTop: '10px'}}
                        onClick={this.savePasswordReset} loading={this.state.passwordResetLoading} block
                        type='primary'>Save</Button>
              </Form>
            </>
            }
          </>
          break
        default:
      }
    }

    return (
      <Layout className={styles.baseLayout}>
        <Layout className='layout-padding'>
          <Row gutter={gutter} type='flex' justify='center' align='middle'>
            <Col align='center' xs={24} style={{maxWidth: '450px'}} className='layout-top-margin'>
              <img src={logo} alt="Oddshero" className={styles.logo} style={{marginBottom: '25px'}}/>
              <Card bordered={false}>
                {component}
                {this.state.loading &&
                <>
                  <div style={{marginTop: '20px'}}>
                    <Puff size={60}/>
                  </div>
                  <Text type='secondary'>Please wait</Text>
                </>
                }
                {this.state.error &&
                <>
                  <Title level={3}>There was an error.</Title>
                  <Paragraph>
                    <Text style={{fontSize: '16px', wordBreak: 'break-all'}} strong copyable>{this.state.error}</Text>
                  </Paragraph>
                  <Paragraph>
                    <Text type='secondary'>If you think this should be working,<br/>please contact our support.</Text>
                  </Paragraph>
                  <Link to='/'><Button block type='primary'>Back</Button></Link>
                </>
                }
              </Card>
            </Col>
          </Row>
        </Layout>
      </Layout>
    )
  }

}

const ActionForm = Form.create({name: 'action'})(Action)

export default firebaseConnect()(withRouter(ActionForm))