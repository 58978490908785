import React from 'react'
import {Button, Card, Col, Layout, message, Row, Typography} from 'antd'
import {gutter} from '../../lib/layout'
import styles from '../../less/BaseLayout.module.less'
import logo from '../../resources/img/hero-logo.svg'
import {firebaseConnect} from 'react-redux-firebase'
import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import {sendVerificationEmail} from '../../store/sagas'

const {Title, Paragraph, Text} = Typography

class VerifyEmail extends React.Component {

  state = {
    resendLoading: false,
    resendDisabled: false
  }

  resendVerificationEmail = async () => {
    try {
      this.setState({resendLoading: true})
      const currentUser = this.props.firebase.auth().currentUser
      if (currentUser.emailVerified) {
        this.setState({resendLoading: false, resendDisabled: true})
        return
      }
      await sendVerificationEmail(currentUser)
      message.success('Email sent')
      this.setState({resendLoading: false, resendDisabled: true})
    } catch (e) {
      message.error(e.message)
      this.setState({resendLoading: false})
      Sentry.captureException(e)
    }
  }

  componentWillUnmount() {
    clearInterval(this.reloadInterval)
  }

  componentDidMount() {
    this.reloadInterval = setInterval(() => {
      this.props.firebase.reloadAuth()
    }, 30000)
  }

  signOut = () => {
    this.props.firebase.logout()
  }

  render() {
    const {auth, profile} = this.props

    // createUser has not been completed yet
    const loading = !_.get(profile, 'mainCurrency')

    return (
      <Layout className={styles.baseLayout}>
        <Layout className='layout-padding'>
          <Row gutter={gutter} type='flex' justify='center' align='middle'>
            <Col align='center' xs={24} style={{maxWidth: '450px'}} className='layout-top-margin'>
              <img src={logo} alt="Oddshero" className={styles.logo}/>
              <Card
                loading={loading}
                bordered={false}
                style={{marginTop: '10px'}}
              >
                <Title level={3}>We sent you an email.</Title>
                <Paragraph>You need to verify your email address before continuing. Therefore we sent an email
                  to</Paragraph>
                <Paragraph>
                  <Text strong style={{fontSize: '16px'}}>{auth.email}</Text>
                </Paragraph>
                <Paragraph>Check your inbox and click on the link to continue.
                </Paragraph>
                <Text type='secondary'>
                  Didn't receive an email? Check your <strong>spam folder</strong>.<br/>Still nothing? Click the resend
                  button
                  below.
                </Text>

                <Button block
                        style={{marginTop: '20px'}}
                        loading={this.state.resendLoading}
                        disabled={this.state.resendDisabled}
                        onClick={this.resendVerificationEmail}>Resend Email</Button>
                <Button block type='link' style={{marginTop: '10px'}} onClick={this.signOut}>Sign Out</Button>
              </Card>
            </Col>
          </Row>
        </Layout>
      </Layout>
    )
  }

}

export default firebaseConnect()(VerifyEmail)