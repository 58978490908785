import React, {Component} from 'react'
import {Button, Card, Checkbox, Col, Divider, Form, Icon, Input, message, Row, Typography} from 'antd'
import {Link} from 'react-router-dom'

const {Text} = Typography

class NormalRegistrationForm extends Component {

  state = {
    emailLoading: false,
    googleLoading: false,
    facebookLoading: false,
    tncAccepted: false,
    gdprAccepted: false
  }

  onChangeTnc =(e) => {
    if(e.target.checked){
      this.setState({tncAccepted: e.target.checked})
    }
  }

  onChangeGdpr =(e) => {
    if(e.target.checked){
      this.setState({gdprAccepted: e.target.checked})
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({emailLoading: true})
        this.props.emailSignup(values.email, values.password)
          .catch(e => {
            this.setState({emailLoading: false})
            message.error(e.message)
          })
      }
    })
  }

  handleGoogle = async () => {
    this.setState({googleLoading: true})
    try {
      await this.props.googleSignup()
    } catch (e) {
      this.setState({googleLoading: false})
      message.error(e.message)
    }
  }

  handleFacebook = async () => {
    this.setState({facebookLoading: true})
    try {
      await this.props.facebookSignup()
    } catch (e) {
      this.setState({facebookLoading: false})
      message.error(e.message)
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form
    return (
      <>
        <Card bordered={false} bodyStyle={{paddingBottom: '32px', paddingTop: '32px'}}>
          <Row>
            <Col>
              <Button loading={this.state.googleLoading}
                      icon="google" type="danger" block onClick={this.handleGoogle}>Sign up with Google</Button>
            </Col>
          </Row>
          <Row style={{marginTop: '15px'}}>
            <Col>
              <Button loading={this.state.facebookLoading} type="primary" block onClick={this.handleFacebook}><Icon
                type="facebook" theme="filled"/>Sign up with Facebook</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Divider style={{color: '#8598ad', fontWeight: '500', fontSize: '14px', margin: '20px 0'}}>or</Divider>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item style={{marginBottom: 0}}>
                  {getFieldDecorator('email', {
                    rules: [{required: true, message: 'Please enter your Email!'}]
                  })(
                    <Input
                      prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}}/>}
                      placeholder="Email"
                      type="email"
                    />
                  )}
                </Form.Item>
                <Form.Item style={{marginBottom: 10}}>
                  {getFieldDecorator('password', {
                    rules: [{required: true, message: 'Please enter your Password!'}]
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                      type="password"
                      placeholder="Password"
                    />
                  )}
                </Form.Item>

                <Form.Item style={{ marginBottom: "0px" }}>
                  {getFieldDecorator('gdpr', {
                    rules: [{required: true, message: 'Please accept our GDPR terms!'}]
                  })(
                    <Checkbox onChange={this.onChangeGdpr} checked={this.state.gdprAccepted} style={{lineHeight: '1.5em', textAlign:'left', fontSize:'0.9em'}}>
                      I have read and agree with the Oddshero <a href='https://oddshero.io/en/privacy-policy/' rel='noopener' target='_blank' >Privacy Policy</a> and <a href='https://oddshero.io/en/terms-and-conditions/' rel='noopener' target='_blank'
                           >Terms & Conditions</a> and I agree, accept, and give my explicit consent to the terms laid out <b>(required)</b>
                    </Checkbox>
                  )}
                </Form.Item>
                <Form.Item style={{ marginBottom: "0px" }}>
                  {getFieldDecorator('tnc', {
                    rules: [{required: true, message: 'Please accept our Terms and Conditions!'}]
                  })(
                    <Checkbox onChange={this.onChangeTnc} checked={this.state.tncAccepted} style={{lineHeight: '1.5em', textAlign:'left', fontSize:'0.9em'}}>
                      I consent to collecting, processing, and storing of my personal data <b>(required)</b>
                    </Checkbox>
                  )}
                </Form.Item>
                <Checkbox style={{lineHeight: '1.5em', textAlign:'left', fontSize:'0.9em'}}>
                  Get email notifications on discounts, promos and news
                </Checkbox>
                <Form.Item style={{marginBottom: 0}}>
                  <Button loading={this.state.emailLoading} block type="primary" htmlType="submit"
                          className="login-form-button">
                    Sign up
                  </Button>
                </Form.Item>
              </Form>

            </Col>
          </Row>
        </Card>
        <Text type='secondary' style={{display: 'block', marginTop: '15px'}}>
          Already have an account? <Link to='/login'>Log in</Link>
        </Text>
      </>
    )
  }
}

const RegistrationForm = Form.create({name: 'normal_registration'})(NormalRegistrationForm)

export default RegistrationForm
