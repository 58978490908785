import {Avatar, Button, Dropdown, Icon, Menu, Popover} from 'antd'
import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'
import styles from '../less/BaseLayout.module.less'
import userMenuStyles from '../less/UserMenu.module.less'
import {HideAt, ShowAt} from 'react-with-breakpoints'
import {firebaseConnect} from 'react-redux-firebase'
import menuSvg from '../resources/img/menu.svg'

const menuItems = [
  <Menu.Item key='/dashboard'><Link to='/dashboard'>Dashboard</Link></Menu.Item>,
  <Menu.Item key='/settings' className="second-step"><Link to='/settings'>Set Bonus</Link></Menu.Item>,
  <Menu.Item key='/tradefeed'><Link to='/tradefeed'>Find Bets</Link></Menu.Item>,
  <Menu.Item key='/my-bets'><Link to='/my-bets'>My Bets</Link></Menu.Item>,
  <Menu.Item key='/how-it-works'><Link to='/how-it-works/getting-started'>How it Works</Link></Menu.Item>,
  // eslint-disable-next-line react/jsx-no-target-blank
  <Menu.Item key='/ebook'><a href="https://firebasestorage.googleapis.com/v0/b/inv-matched-betting.appspot.com/o/ebooks%2FOddshero%20-%20Matched%20Betting%20Ebook.pdf?alt=media&token=3a4cd017-165c-4757-b885-7f99dd8815a6" rel='noopener' target="_blank">Free Ebook</a></Menu.Item>,
  // eslint-disable-next-line react/jsx-no-target-blank
  <Menu.Item key='/blog'><a href="https://www.oddshero.io/en/blog" rel='noopener' target="_blank">Blog</a></Menu.Item>
]

const userItems = (admin, signOut) => [
  admin ?
    <Menu.Item key='admin'>
      <Link to="/admin/user-management">Admin</Link>
    </Menu.Item> : null,
  <Menu.Item key='my-profile'>
    <Link to="/my-profile">My Profile</Link>
  </Menu.Item>,
  <Menu.Divider key='divider'/>,
  <Menu.Item key='logout' className='logout'>
    <Button block type='danger' ghost onClick={signOut}>Sign Out</Button>
  </Menu.Item>
]

class TopMenu extends Component {

  signOut = () => this.props.firebase.logout()

  render() {
    let {location: {pathname}, admin, subscriptionLevel, announcement} = this.props
    const split = pathname.split('/')
    let selectedKey = pathname
    if (split.length >= 2) {
      selectedKey = split[1]
    }

    const userMenu = userItems(admin, this.signOut)

    return (
      <>
        <ShowAt breakpoint='largeAndBelow'>
          <div style={{float: 'right'}}>
            <Dropdown placement='bottomRight' trigger={['click']} overlay={
              <Menu>
                {subscriptionLevel === 1 &&
                <Link to='/my-profile#subscription'>
                  <Button style={{margin: '5px 0 5px 10px'}} type='primary'>Upgrade</Button>
                </Link>
                }
                {menuItems}
                {userMenu}
              </Menu>
            }>
              <Button className={styles.menuBurger} type='link'>
                <img style={{height: '32px'}} src={menuSvg} alt='Menu'/>
              </Button>
            </Dropdown>
          </div>
          <AnnouncementPopover announcement={announcement}/>
        </ShowAt>
        <HideAt breakpoint='largeAndBelow'>
          <div className={userMenuStyles.userMenu}>
            <Dropdown trigger={['click']} placement='topRight' overlay={<Menu>
              {userMenu}
            </Menu>}
            >
              <div style={{cursor: 'pointer'}}>
                <Avatar className={userMenuStyles.avatar} icon='user'/>
                <Icon style={{marginLeft: '5px'}} type='down'/>
              </div>
            </Dropdown>

          </div>
          {subscriptionLevel === 1 &&
          <div style={{float: 'right', marginRight: '20px'}}>
            <Link to='/my-profile#subscription'>
              <Button type='primary'>Upgrade</Button>
            </Link>
          </div>
          }
          <AnnouncementPopover announcement={announcement}/>
          <Menu
            theme='light'
            mode='horizontal'
            selectedKeys={[`/${selectedKey}`]}
            className={styles.topMenu}
          >
            {menuItems}
          </Menu>
        </HideAt>
      </>
    )

  }
}

const AnnouncementPopover = ({announcement}) =>
  announcement.show && !announcement.alwaysShow ?
    <div style={{float: 'right', marginRight: '20px'}}>
      <Popover
        content={<div dangerouslySetInnerHTML={{__html: announcement.message}}/>}
        title={announcement.title}
        placement='bottomRight'
        overlayStyle={{maxWidth: '300px'}}
        trigger="click"
      >
        <Icon type="exclamation-circle" className={styles.announcement} theme="filled"/>
      </Popover>
    </div>
    : null

export default firebaseConnect()(withRouter(TopMenu))
