import React, {Component} from 'react'
import {Button, Form, Icon, Input, message, Modal, Typography} from 'antd'

const {Text} = Typography

class ForgotPasswordForm extends Component {
  state = {visible: false}

  showModal = () => {
    this.setState({
      visible: true
    })
  }

  handleCancel = e => {
    this.setState({
      visible: false
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({loading: true})
        this.props.sendResetPassword(values.email).then(() => {
          message.success('You will receive an email shortly')
          this.setState({loading: false, visible: false})
        }).catch(error => {
          message.error(error.toString())
        })
      }
    })
  }

  render() {
    const {getFieldDecorator} = this.props.form

    return (
      <div>
        <Button style={{display: 'block', fontSize: '12px', margin: '15px 0 0 0', padding: 0, height:'auto', border: 'none'}} type='link' onClick={this.showModal}>Forgot
          your Password?</Button>
        <Modal
          title="Forgot Password"
          visible={this.state.visible}
          onOk={this.handleSubmit}
          onCancel={this.handleCancel}
          confirmLoading={this.state.loading}
          width={450}
        >
          <Form onSubmit={this.handleSubmit}>
            <Form.Item style={{marginBottom: 10}}>
              {getFieldDecorator('email', {
                rules: [{required: true, message: 'Please enter your email!'}]
              })(
                <Input
                  prefix={<Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}}/>}
                  placeholder="Email"
                  type="email"
                />
              )}
            </Form.Item>
            <Text type='secondary'>We will send an email to your address with instructions on how to reset your
              password.</Text>
          </Form>
        </Modal>
      </div>
    )
  }
}

const ForgotPasswordModal = Form.create({name: 'forgot_password'})(ForgotPasswordForm)

export default ForgotPasswordModal















