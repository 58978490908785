import React from 'react'
import {Button, Card, Col, Empty, Layout, Row, Typography} from 'antd'
import {gutter} from './lib/layout'
import noTradesLogo from './resources/img/hero-flying.svg'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  static getDerivedStateFromError(error) {
    return {hasError: true}
  }

  render() {
    if (this.state.hasError) {
      return (
        <Layout className='layout-padding'>
          <Row gutter={gutter}>
            <Col xl={{span: 16, offset: 4}}>
              <Card bordered={false} className='layout-top-margin'>
                <Empty
                  image={noTradesLogo}
                  imageStyle={{
                    height: 200
                  }}
                  description={
                    <span>
                  <Typography.Title level={3}>Woooops.</Typography.Title>
                  There was an issue loading the page. Try reloading and see if it fixes the issue.
                </span>
                  }
                >
                  <Button type="primary" size='large' onClick={() => window.location.reload(true)}>
                    Reload
                  </Button>
                </Empty>
              </Card>
            </Col>
          </Row>
        </Layout>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary