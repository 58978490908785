import {Col, Layout, Row} from 'antd'
import React from 'react'
import {connect} from 'react-redux'
import logo from '../../resources/img/hero-logo.svg'
import LoginForm from './LoginForm'
import RegistrationForm from './RegistrationForm'
import {gutter} from '../../lib/layout'
import styles from '../../less/BaseLayout.module.less'
import '../../less/Login.less'
import {Route, Switch} from 'react-router-dom'
import {freshChatToken} from '../../config/freshChat'
import FreshChat from 'react-freshchat'

class Login extends React.Component {

  render() {
    return (
      <Layout className={styles.baseLayout}>
        <Layout className='layout-padding'>
          <Row gutter={gutter} type='flex' justify='center' align='middle'>
            <Col align='center' xs={24} style={{maxWidth: '450px'}} className='layout-top-margin'>
              <img src={logo} alt="Oddshero" className={styles.logo} style={{marginBottom: '25px'}}/>

              <Switch>
                <Route path='/signup'>
                  <RegistrationForm
                    emailSignup={this.props.emailSignup}
                    googleSignup={this.props.googleSignup}
                    facebookSignup={this.props.facebookSignup}
                  />
                </Route>

                <Route path='/login'>
                  <LoginForm
                    googleSignup={this.props.googleSignup}
                    facebookSignup={this.props.facebookSignup}
                    emailLogin={this.props.emailLogin}
                    sendResetPassword={this.props.sendResetPassword}
                  />
                </Route>
              </Switch>
              <FreshChat
                siteId="ODDSHERO"
                token={freshChatToken}
              />
            </Col>
          </Row>
        </Layout>
      </Layout>
    )
  }
}

export default connect()(Login)
